<template>
  <div class="page-link-code-by-code">

    <h3>1. Выберете товар</h3>

    <InputSearch code="F1" placeholder="Поиск по названию / коду" @submit="getData" ref="inputSearch"/>
    <Loader :state="load"/>

    <table v-if="!load && goodsList.length">
        <tr>
          <th>
            Наименование
          </th>
          <th>
            Кол.
          </th>
          <th></th>
        </tr>
        <tr v-for="g in goodsList" :key="g.id">
          <td>
            <b>[{{g.code}}]</b> {{ g.name }}
            <a href="#" @click.prevent="image_uri = g.photo">изображение</a>
          </td>
          <td><strong>{{ g.amount }}</strong></td>
          <td>
            <button @click="select(g)">✅</button>
          </td>
        </tr>
      </table>
    <EmptyData text="Нет результатов с пустым штрихкодом" v-else-if="!load"/>

    <template v-if="goodsSelect">
      <h3>2. Сканируйте штрихкод для товара</h3>
      <h4>{{ goodsSelect.name }}</h4>

      <InputSearch code="F2" placeholder="Штрихкод" @submit="checkCode" ref="inputCode"/>
      <Loader :state="checkCodeLoad"/>

      <template v-if="goodsFromCode">
        <table>
          <tr>
            <th>
              Под этим кодом уже есть товар:
            </th>
          </tr>
          <tr>
            <td>
              <b>[{{goodsFromCode.code}}]</b> {{ goodsFromCode.name }}
              <a href="#" @click.prevent="image_uri = goodsFromCode.photo">изображение</a>
            </td>
          </tr>
        </table>
        <button class="full" @click="showConfirm = true">Перезаписать штрих-код</button>
      </template>

    </template>

    <ModalImage :uri="image_uri" @close="image_uri = ''"/>

    <ModalConfirm :show="showConfirm" :status="status" @confirm="confirm" @cancel="cancel">
      <template #title><h3>Названить код: {{ code }} ?</h3></template>
      <template #body>
        <p v-if="goodsSelect">{{ goodsSelect.name }}</p>
        <div class="flex">
          <div>
            <button type="button" @click="change(-1)">-</button>
            <input type="number" v-model="amount" min="1" step="1">
            <button type="button" @click="change(1)">+</button>
          </div>
          <small>Введите кол-во, если это коробка с несколькими товарами</small>
        </div>
      </template>
    </ModalConfirm>

  </div>
</template>

<script>
import {onMounted, onUnmounted, ref} from 'vue'
import ModalConfirm from "@/components/ModalConfirm";
import Loader from "@/components/Loader";
import InputSearch from "@/components/InputSearch";
import EmptyData from "@/components/EmptyData";
import ModalImage from "@/components/ModalImage";

export default {
  components: {ModalImage, EmptyData, InputSearch, Loader, ModalConfirm},
  setup(props, {emit}) {
    emit('meta', {title: 'Привязка по товару', back: '/link_code'})

    let load = ref('Загрузка...')
    let goodsList = ref([])
    let goodsSelect = ref(null)
    let search = ''
    let image_uri = ref('')

    const getData = async (params = {}) => {
      params.codeEmpty = true
      params.page = params.page ? params.page : 1
      params.search !== undefined ? search = params.search : params.search = search

      goodsSelect.value = null

      load.value = 'Загрузка...'
      let data = await api.getGoods(params)
      goodsList.value = data.data
      load.value = ''

      if(goodsList.value.length === 1) {
        select(goodsList.value[0])
      }
    }
    getData()

    const inputCode = ref(null)
    const inputSearch = ref(null)
    const select = (g) => {
      goodsSelect.value = g
      setTimeout(() => {
        inputCode.value.input.focus()
      })
    }

    let code = ref('')
    let checkCodeLoad = ref('')
    let goodsFromCode = ref(null)
    const checkCode = async (params) => {
      code.value = params.search
      checkCodeLoad.value = 'проверка кода...'
      goodsFromCode.value = null
      if (params.search) {
        const data = await api.getGoodsFind(params.search)
        goodsFromCode.value = data
        if (data === '') showConfirm.value = true
      }
      checkCodeLoad.value = ''
    }

    let amount = ref(1)
    const change = (val) => {
      try {
        navigator.vibrate(100);
      } catch (er) {}
      amount.value += val
      if(amount.value < 1) amount.value = 1
    }

    let showConfirm = ref(false)
    let status = ref('')
    const confirm = async () => {
      let params = {
        id: goodsSelect.value.id,
        code: code.value,
        amount: amount.value
      }

      status.value = 'В процессе...'
      status.value = await api.setGoods(params)
    }

    const cancel = () => {
      showConfirm.value = false
      if (status.value === 'success') {
        goodsSelect.value = null
        goodsFromCode.value = null
        status.value = ''
        amount.value = 1
        search = ''
        getData()
        inputSearch.value.input.value = ''
        inputSearch.value.input.focus()
      }
    }

    const keydown = (event) => {
      if(!goodsSelect.value) return
      if (event.code === 'F13' && inputCode.value.input !== document.activeElement) {
        inputCode.value.input.value = ''
        inputCode.value.input.focus()
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', keydown)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', keydown)
    })

    return {
      load, goodsList, goodsSelect, getData, inputCode, inputSearch, select,
      code, amount, change, checkCodeLoad, checkCode, goodsFromCode,
      showConfirm, confirm, cancel, status, image_uri
    }
  }
}
</script>

<style lang="less" scoped>
h3 {
  font-size: 18px;
  text-align: center;
  margin: 10px 0;
}

h4 {
  font-size: 16px;
  text-align: center;
  margin: 10px;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input {
    width: 80px;
    text-align: center;
    margin: 0 10px;
  }

  small {
    margin: 10px 0;
  }
}
</style>
